<template>
    <div class="mainbox">
        <div class="detail_right">
            <div class="detail_right_til">师资团队</div>
            <div class="question_list">
                <div @mouseenter="mouseIn(itemd)" @mouseleave="mouseOut(itemd)" v-for="(itemd, indexd) in lists"
                    :key="indexd" class="slide_item">
                    <div class="slide_item_bot">
                        <div class="slide_item_head">
                            <img :src="itemd.teacherImg" alt="">
                        </div>
                        <div class="ter_name elp">{{ itemd.teacherName }}</div>
                        <div class="line"></div>
                        <div class="slide_item_msg twoEllipsis">{{ itemd.teacherIntroduction }}</div>
                    </div>
                    <div v-if="itemd.isShow" class="pop">
                        <div class="p_text ">
                            {{ itemd.teacherIntroduction }}
                        </div>
                    </div>
                </div>
                <el-empty style="margin: 0 auto;" v-if="!lists || lists.length == 0" description="暂无数据"></el-empty>

            </div>
            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="pageNum" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[8, 12, 24]" />
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            lists: [],
            pageSize: 8,
            pageNum: 1,
            total: 0,
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.selectSchoolTeacherPage()



    },
    mounted() {

    },
    methods: {
        selectSchoolTeacherPage() {
            know.selectSchoolTeacherPage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                isTeacher: 1,
                schoolId: this.id,
            }).then((res) => {
                this.lists = res.rows
                this.total = res.total

            });
        },
        mouseIn(item) {
            item.isShow = true
            this.$forceUpdate()
        },
        mouseOut(item) {
            item.isShow = false
            this.$forceUpdate()

        },
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.selectSchoolTeacherPage()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.selectSchoolTeacherPage()
        },

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    .detail_right {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        padding-bottom: 30px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 20px;
            color: #444444;
            font-weight: bold;
            border-bottom: 1px solid #DFDFDF;
            padding-left: 30px;
        }

        .question_list {
            padding: 30px;
            padding-bottom: 0;
            line-height: 1.5;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            flex-wrap: wrap;

            .slide_item {
                position: relative;
                width: 241px;
                height: 272px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                cursor: pointer;
                margin-bottom: 24px;
                margin-right: 50px;

                .slide_item_bot {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    width: 242px;
                    height: 232px;
                    background: linear-gradient(180deg, #FFFFFF 0%, #F7F3EE 100%);
                    border-radius: 6px 6px 6px 6px;
                    padding: 0 13px;

                    .slide_item_head {
                        position: absolute;
                        width: 145px;
                        height: 145px;
                        border-radius: 50%;
                        border: 1px solid #BEBFC1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: -40px;

                        img {
                            width: 124px;
                            height: 124px;
                            border-radius: 50%;
                        }
                    }

                    .ter_name {
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        text-align: center;

                        margin-top: 110px;
                    }

                    .line {
                        width: 29px;
                        height: 2px;
                        background: #BD1212;
                        border-radius: 2px 2px 2px 2px;
                        margin: 0 auto;
                        margin-top: 7px;
                    }

                    .slide_item_msg {
                        width: 200px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #666666;
                        margin-top: 12px;
                    }
                }

                .pop {
                    position: absolute;
                    width: 241px;
                    height: 272px;
                    background: rgba(44, 44, 44, 0.8);
                    border-radius: 8px 8px 8px 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .p_text {
                        width: 211px;
                        height: 190px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #FFFFFF;
                        overflow-y: scroll;
                    }

                }

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>